import { faker } from '@faker-js/faker';
import { IInsightsViewerBlock } from 'app/containers/RootInsights/types';

const { datatype, lorem, system } = faker;

export const insightsItemDataBlockViewerMock: () => IInsightsViewerBlock =
  () => ({
    id: 'IInsightsViewerBlock',
    type: 'pdf-viewer',
    title: 'Insights Item Data Block Text',
    file_name: system.fileName(),
    file_id: datatype.uuid(),
    evidence_index: datatype.number({ max: 10 }),
    evidence_text: lorem.sentence(100),
    evidence_id: datatype.uuid(),
    page_number: datatype.number({ max: 10 }),
    // rejected: true,
    // rejection_reason: lorem.sentence(100),
  });
