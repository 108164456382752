/**
 *
 * TableRowGroup
 *
 */

import React from 'react';
import { StyledTableCell, StyledTableRow, StyledTableRowSep } from '../styled';
import { TableCell } from '../TableCell';
import { useTableRow } from './TableRowContext';
import { TableRow } from './index';
import { IGroupRow } from '../types';
import { SecondaryActionCell } from '../TableCell/SecondaryActionCell';
import { CheckBox } from '../../../components/Inputs/CheckBox';

export const TableRowGroup = () => {
  const {
    row,
    handleExpanded,
    isExpanded,
    hideCol,
    selectable,
    selected_ids,
    onSelectRow,
  } = useTableRow();

  const { cells, table } = row as IGroupRow;

  const isChecked = table.rows.reduce<boolean>((acc, i) => {
    if (!acc) {
      return Boolean(selected_ids?.includes(i.id));
    }
    return acc;
  }, false);

  const handleChange = event =>
    onSelectRow && onSelectRow(row, event.target.checked);

  return (
    <>
      <StyledTableRow
        onClick={handleExpanded}
        isHover
        collapseHead
        isExpanded={isExpanded}
      >
        {selectable && (
          <StyledTableCell is_empty>
            <SecondaryActionCell>
              <CheckBox
                checked={isChecked}
                onChange={handleChange}
                size="small"
              />
            </SecondaryActionCell>
          </StyledTableCell>
        )}
        {cells
          .filter((i, n) => n !== hideCol)
          .map((cell, index) => (
            <TableCell key={index} index={index} cell={cell} />
          ))}
      </StyledTableRow>
      {isExpanded &&
        table.rows.map((rowItem, n, arr) => (
          <TableRow
            key={`${n}_${rowItem.id}`}
            row={rowItem}
            isExpanded={isExpanded}
            collapseBody
            lasOfGroup={arr.length === n + 1}
          />
        ))}
      {isExpanded && table.rows.length > 0 && <StyledTableRowSep />}
    </>
  );
};
