/**
 *
 * usePageParams
 *
 */
import { IUsePageParams } from './types';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { ICompoundFilter } from 'app/containers/Filter/types';
import { IOrder, ITableRow } from 'app/containers/AbstractTable/types';
import { parseSearch } from './utils';

export const usePageParams: IUsePageParams = () => {
  const history = useHistory();

  const { search } = history.location;

  const parsedSearch = queryString.parse(search);

  const pageParams = parseSearch(search);

  const { selected_ids } = pageParams;

  const onChangePage = (e, page: number) => {
    history.push({
      search: queryString.stringify({
        ...parsedSearch,
        page,
      }),
    });
  };

  const onChangeLimit = (limit: number) => {
    history.push({
      search: queryString.stringify({
        ...parsedSearch,
        limit,
        page: 1,
      }),
    });
  };

  const onChangeOrder = (order: IOrder | null) => {
    history.push({
      search: queryString.stringify({
        ...parsedSearch,
        order: order ? JSON.stringify(order) : undefined,
        page: 1,
      }),
    });
  };

  const onChangeFilter = (filter: ICompoundFilter | null) => {
    history.push({
      search: queryString.stringify({
        ...parsedSearch,
        filter: filter ? JSON.stringify(filter) : 'null',
        page: 1,
      }),
    });
  };

  const onSelectRow = (row: ITableRow, checked: boolean) => {
    let selectedList: string[] = selected_ids ? [...selected_ids] : [];

    if (row.type === 'group') {
      const groupIds = row.table.rows.map(i => i.id);
      selectedList = checked
        ? [...selectedList, ...groupIds]
        : selectedList.filter(i => !groupIds.includes(i));
    } else {
      selectedList = checked
        ? [...selectedList, row.id]
        : selectedList.filter(i => i !== row.id);
    }

    history.push({
      search: queryString.stringify({
        ...parsedSearch,
        selected_ids: JSON.stringify(selectedList),
      }),
    });
  };

  const selectAllRows = (rows: ITableRow[], checked: boolean) => {
    let selectedList: string[] = selected_ids ? [...selected_ids] : [];

    const selectAll = (tableRows: ITableRow[]) => {
      tableRows.forEach(row => {
        if (row.type === 'group') {
          selectAll(row.table.rows);
        } else {
          selectedList = checked
            ? [...selectedList, row.id]
            : selectedList.filter(i => i !== row.id);
        }
      });
    };

    selectAll(rows);

    history.push({
      search: queryString.stringify({
        ...parsedSearch,
        selected_ids: JSON.stringify(selectedList),
      }),
    });
  };

  const onChangeShowSelected = (value: boolean) => {
    history.push({
      search: queryString.stringify({
        ...parsedSearch,
        show_selected: value,
      }),
    });
  };

  return {
    pageParams,
    onChangePage,
    onChangeLimit,
    onChangeOrder,
    onChangeFilter,
    onSelectRow,
    selectAllRows,
    onChangeShowSelected,
  };
};
