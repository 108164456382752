import React from 'react';
import { IDocumentStatusCell } from 'app/containers/AbstractTable/types';
import { Box } from 'app/components/Box';
import { Icon } from 'app/components/Icon';
import { Typography } from 'app/components/Typography';
import { Tooltip } from 'app/components/Tooltip';
import { Link } from 'app/components/Link';
import { usePartyDocumentsContext } from 'app/containers/RootThirdParty/PageThirdParty/ThirdPartyDocuments/Context';

export const CellDocumentStatus = ({ data }: IDocumentStatusCell) => {
  const {
    status,
    validation_error,
    document_id,
    document_name,
    document_business_name = '',
    tp_name = '',
    scope_name = '',
    override_reason = '',
    possible_relations = [],
    resolved_relation,
    document_business_name_count,
  } = data;

  const { openSubmitPasswordPopup, openTargetEntityPopup } =
    usePartyDocumentsContext();

  const handleOpenSubmitPasswordPopup = () => {
    openSubmitPasswordPopup({ document_id, document_name });
  };

  const handleOpenTargetEntityPopup = () => {
    openTargetEntityPopup({
      document_id,
      document_name,
      document_business_name,
      tp_name,
      scope_name,
      possible_relations,
      document_business_name_count,
    });
  };

  switch (status) {
    case 'TargetEntityMismatch':
      return (
        <Box display="flex" alignItems="center" gap=".5rem">
          <Icon name="attention" height="1.5rem" />
          <Typography variant="body2">
            Business name mismatch -{' '}
            <Link onClick={handleOpenTargetEntityPopup}>Resolve</Link>
          </Typography>
        </Box>
      );
    case 'TargetEntityMismatchKeep':
      return (
        <Tooltip title="Business name mismatch">
          <div>
            <Box display="flex" alignItems="center" gap=".5rem">
              <Icon name="attention" height="1.5rem" />
              <Typography variant="body2">Analyzed</Typography>
            </Box>
          </div>
        </Tooltip>
      );
    case 'TargetEntityMismatchOverrideAnalyzed':
      if (!!override_reason) {
        return (
          <Tooltip title={`Override - ${override_reason}`}>
            <div>
              <Box display="flex" alignItems="center" gap=".5rem">
                <Icon name="attention" height="1.5rem" />
                <Typography variant="body2">Analyzed</Typography>
              </Box>
            </div>
          </Tooltip>
        );
      }
      if (
        resolved_relation &&
        resolved_relation.label &&
        document_business_name
      ) {
        return (
          <Tooltip
            title={`${document_business_name} - ${resolved_relation.label}`}
          >
            <div>
              <Box display="flex" alignItems="center" gap=".5rem">
                <Icon name="attention" height="1.5rem" />
                <Typography variant="body2">Analyzed</Typography>
              </Box>
            </div>
          </Tooltip>
        );
      }
      return null;
    case 'Uploaded':
      return (
        <Box display="flex" alignItems="center" gap=".5rem">
          <Icon name="upload" />
          <Typography variant="body2">{status}</Typography>
        </Box>
      );
    case 'Analyzing':
      return (
        <Box display="flex" alignItems="center" gap=".5rem">
          <Icon name="analizing" />
          <Typography variant="body2">{status}</Typography>
        </Box>
      );
    case 'Reanalyzing':
      return (
        <Box display="flex" alignItems="center" gap=".5rem">
          <Icon name="analizing" />
          <Typography variant="body2">{status}</Typography>
        </Box>
      );
    case 'Analyzed': {
      return (
        <Box display="flex" alignItems="center" gap=".5rem">
          <Icon name="check" />
          <Typography variant="body2">{status}</Typography>
        </Box>
      );
    }
    case 'PasswordRequired':
      return (
        <Box display="flex" alignItems="center" gap=".5rem">
          <Icon name="lock" />
          <Link variant="body2" onClick={handleOpenSubmitPasswordPopup}>
            Submit password
          </Link>
        </Box>
      );
    case 'Error':
      return (
        <Typography
          display="flex"
          alignItems="center"
          gap=".5rem"
          color="error"
        >
          <Icon name="close" />
          <Typography variant="body2" color="error">
            {status}
          </Typography>
          {validation_error && (
            <Tooltip title={validation_error}>
              <div>
                <Icon name="question" />
              </div>
            </Tooltip>
          )}
        </Typography>
      );
    default:
      return null;
  }
};
