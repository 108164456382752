import { IDownloadWithSignUrlCell } from 'app/containers/AbstractTable/types';
import { Box } from 'app/components/Box';
import { Icon } from 'app/components/Icon';
import { IconButton } from 'app/components/Buttons/IconButton';
import { Progress } from 'app/components/Progress';
import { useDownloadSingleFile } from 'app/hooks/useDownloadSingleFile';

export const CellDownloadWithSignUrl = ({ data }: IDownloadWithSignUrlCell) => {
  const { loading, handleDownloadFile } = useDownloadSingleFile();

  const { document_name, document_id } = data;

  const handleDownload = async () => {
    handleDownloadFile(document_id, document_name);
  };

  return (
    <Box>
      <IconButton
        color="inherit"
        onClick={handleDownload}
        data-test="button_download"
      >
        {loading ? (
          <Progress size="1rem" type="circular" />
        ) : (
          <Icon name="download" />
        )}
      </IconButton>
    </Box>
  );
};
