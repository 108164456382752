import { RequestApi } from 'api/types';
import { USE_MOCK } from 'utils/constants';
import { request } from 'utils/request';
import { apiDelay } from 'api/utils';
import {
  IWithInsightId,
  WithPartyId,
} from 'app/hooks/useGetAbstractData/typesParamsConfig';
import { IInsightsItem } from 'app/containers/RootInsights/types';
import { getInsightDataMock } from 'api/insights/getPartyInsightItemAPI/mock';

export interface APIPayloadPartyInsightItem
  extends WithPartyId,
    IWithInsightId {}

export interface APIResponsePartyInsightItem extends IInsightsItem {}

export const getPartyInsightItemAPI: RequestApi<
  APIPayloadPartyInsightItem,
  APIResponsePartyInsightItem
> = async ({ thirdPartyId, insightId }, signal) => {
  if (USE_MOCK) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(getInsightDataMock(insightId));
      }, apiDelay);
    });
  }

  return request(
    `/third_parties/${thirdPartyId}/insights/latest/${insightId}`,
    { signal },
  );
};
