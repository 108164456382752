import queryString from 'query-string';
import { IPageParams } from './types';

export const parseSearch = (search: string): IPageParams => {
  const { filter, order, page, trend, limit, selected_ids, show_selected } =
    queryString.parse(search);
  return {
    filter: typeof filter === 'string' ? JSON.parse(filter) : undefined,
    order: typeof order === 'string' ? JSON.parse(order) : null,
    page: typeof page === 'string' ? parseInt(page, 10) : undefined,
    limit: typeof limit === 'string' ? parseInt(limit, 10) : undefined,
    trend: typeof trend === 'string' ? trend : undefined,
    selected_ids:
      typeof selected_ids === 'string' ? JSON.parse(selected_ids) : undefined,
    show_selected:
      typeof show_selected === 'string' && show_selected === 'true',
  };
};
